


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import Parameters from '@/components/_uikit/Parameters.vue'
import { ITab, NameValueResource } from '@/store/types'

/**
 * Компонент используется для случаев, когда таб является ссылкой на конкретную страницу
 */

@Component({
  components: {
    Parameters,
  },
  inheritAttrs: false,
})
export default class LinkTabs extends Vue {
  @Prop({ required: true })
  private tabs!: ITab[]

  @Prop({ default: () => ([]) })
  private menu!: NameValueResource[]

  @Prop({ default: false })
  private vertical!: boolean

  @Prop({ default: false })
  private transparent!: boolean

  @Prop({ default: false })
  private roundedBottomOff!: boolean

  @Prop({ default: false })
  private roundedOff!: boolean

  @Prop({ default: null })
  private value!: number

  @Prop({ default: 0 })
  private hideIconWidth!: number

  private innerValue: number | null = null

  private mounted() {
    if (this.value != null) {
      this.innerValue = this.value
    }
  }

  private handleChangeIndex (index: number) {
    this.$emit('update:value', index)
  }

  private handleMenuItemClick (action: string) {
    this.$emit('click-menu-item', action)
  }

  @Watch('value')
  private watchValue (value: number) {
    if (this.value != null) {
      this.innerValue = value
    }
  }
}
