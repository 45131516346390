























import { Component } from 'vue-property-decorator'

// components
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
// mixins
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import ManagerDiscountModule from '@/store/modules/manager/discounts'
// utils
import { openNewTab } from '@/utils/functions'

@Component({
  components: {
    ButtonTextIcon,
    LinkTabs,
  },
})
export default class Discounts extends PermissionsMixin {
  private get filter() {
    return ManagerDiscountModule.discountsStatsFilter
  }

  private get tabs() {
    return [
      {
        name: 'Скидки',
        to: { name: 'manager.commerce.discount.list' },
      },
      {
        name: 'Статистика по скидкам',
        to: { name: 'manager.commerce.discount.stats' },
      },
    ]
  }

  private mounted() {
    if (this.$route.name === 'manager.commerce.discount') {
      this.$router.replace({ name: 'manager.commerce.discount.list' })
    }
  }

  private handleExport() {
    openNewTab('/manager/sales/statistic/export', this.filter)
  }
}
